import React from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">

      <div className="navbar-brand"><Link to="/">NG</Link></div>

      <ul className="navbar-pages">
        <li><Link to="/frontend">Frontend</Link></li>
        <li><Link to="/backend">Backend</Link></li>
        <li><Link to="/mobile">Mobile</Link></li>
        <li><Link to="/more">More</Link></li>
      </ul>

      <Link to="/contact" className="navbar-btn-contact">Let's Talk!</Link>

    </nav>
  );
};

export default Navbar;
