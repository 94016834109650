
import React from 'react';

function More() {
  return (
    <div className="App">
      <p className="mockContent"> [ More Content Goes Here ] </p>
    </div>
  );
}

export default More;
