
import React from 'react';

function Contact() {
  return (
    <div className="App">
      <p className="mockContent"> [ Contact Content Goes Here ] </p>
    </div>
  );
}

export default Contact;
