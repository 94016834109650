
import React from 'react';

function Frontend() {
  return (
    <div className="App">
      <p className="mockContent"> [ Frontend Content Goes Here ] </p>
    </div>
  );
}

export default Frontend;
