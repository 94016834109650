import React from 'react';

import './Footer.css';

import githubIcon from './images/github-icon.png';
import linkedinIcon from './images/linkedin-icon.png';
import reactIcon from './images/react-icon.png';

const Footer = () => {
  return (
    <div className="footer">

        <a className="footer-left" href="https://www.reactjs.com" target="_blank" rel="noopener noreferrer">
            Built with React
            <img src={reactIcon} alt="React.js" style={{ verticalAlign: 'middle' }} />
        </a>

        <div className="footer-right">
            <a href="https://github.com/nickglidden" target="_blank" rel="noopener noreferrer">
            <img src={githubIcon} alt="GitHub" />
            </a>
            <a href="https://www.linkedin.com/in/nickglidden" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" />
            </a>
        </div>

    </div>
  );
};

export default Footer;
