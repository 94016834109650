
import React from 'react';

function Mobile() {
  return (
    <div className="App">
      <p className="mockContent"> [ Mobile Content Goes Here ] </p>
    </div>
  );
}

export default Mobile;
