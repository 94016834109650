
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Navbar from './Navbar.js';
import Footer from './Footer.js';

import Home from './Home.js';
import Frontend from './Frontend.js';
import Backend from './Backend.js';
import Mobile from './Mobile.js';
import More from './More.js';
import Contact from './Contact.js';

function App() {
  return (
    <div className="App">
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/frontend" element={<Frontend />} />
          <Route path="/backend" element={<Backend />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/more" element={<More />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
