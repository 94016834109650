
import React from 'react';

function Home() {
  return (
    <div className="App">
      <p className="mockContent"> [ Home Content Goes Here ] </p>
    </div>
  );
}

export default Home;
